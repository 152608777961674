<template>
  <div class="">
    <el-dialog
      :visible.sync="dialogVisible"
      width="700px"
      :show-close="false"
      :modal-append-to-body="false"
      :lock-scroll="false"
    >
      <div class="details">
        <span class="closeicon" @click="closebox()"
          ><i class="el-icon-error"></i
        ></span>
        <div class="wb-tit font18">面试日程表确认</div>
        <div class="liuchengbox">
          <el-timeline>
            <el-timeline-item
              icon="el-icon-time"
              color="#00bcff"
              size="large"
              :timestamp="item.interview.interviewDate"
              placement="top"
              v-for="(item, index) in list"
              :key="index"
            >
              <el-card>
                <div class="process">
                  <div class="p-left">
                    <div class="pl-one">
                      <div class="p-picurl">
                        <img
                          :src="
                            'https://freemen.work/user/file/view/' +
                            item.userCVVo.avatar
                          "
                        />
                      </div>
                      <div class="p-info">
                        <div class="pn-name">
                          <div class="namemain">
                            <span class="font22">{{
                              item.userCVVo.displayName
                            }}</span>
                            <img
                              src="../../assets/images/person/boy.png"
                              v-if="item.userCVVo.sex == 1"
                            />
                            <img
                              src="../../assets/images/person/girl.png"
                              v-else
                            />
                          </div>
                        </div>
                        <div class="beizhu font14">
                          {{ item.userCVVo.expYear }}年 |
                          {{
                            item.userCVVo.educationList.length
                              ? item.userCVVo.educationList[
                                  item.userCVVo.educationList.length - 1
                                ].educationType.itemText
                              : "/"
                          }}
                          |
                          {{
                            new Date().getYear() -
                            new Date(item.userCVVo.birthday).getYear()
                          }}岁 | {{ item.userCVVo.jobLevelNames }}
                        </div>
                      </div>
                    </div>
                    <div class="pl-two font16">
                      应聘岗位：{{ item.userCVVo.companyJobName }}
                    </div>
                  </div>
                  <el-divider direction="vertical"></el-divider>
                  <div class="p-right font14">
                    <div class="">{{ item.interview.interviewBuild }}</div>
                    <div class="">
                      联系人：{{ item.interview.interviewContact }}
                    </div>
                    <div class="">
                      联系电话：{{ item.interview.interviewPhone }}
                    </div>
                    <div class="">
                      面试类型：{{
                        item.interview.interviewType == 2
                          ? "线上面试"
                          : "线下面试"
                      }}
                    </div>
                    <div class="">
                      面试时间：{{ item.interview.interviewDate }}
                    </div>
                  </div>
                </div>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
        <div class="btn-caozuo">
          <el-button type="primary" class="qx" @click="backed()"
            >返回批量编辑</el-button
          >
          <el-button type="primary" class="yy" @click="submit"
            >发布面试</el-button
          >
        </div>
      </div>
    </el-dialog>
    <!-- 短信通知弹窗 -->
    <el-dialog
      :visible.sync="senddxDialogVisible"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="updown_box">
        <div class="ub-img">
          <img src="../../assets/images/common/gou-chenggong.png" />
        </div>
        <div class="ub-text font22">面试发送成功，是否短信通知</div>
        <div class="btnbox">
          <el-button class="qx" type="primary" @click="closeSmsDialog"
            >取消</el-button
          >
          <el-button class="qr" type="primary" @click="sendMes">通知</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 短信不足弹窗 -->
    <el-dialog
      :visible.sync="dxbzDialogVisible"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div class="updown_box">
        <div class="ub-img">
          <img src="../../assets/images/person/failcha.png" />
        </div>
        <div class="ub-text font22">当前短信卡额度不足，是否购买？</div>
        <div class="btnbox">
          <el-button
            class="qx"
            type="primary"
            @click="dxbzDialogVisible = false"
            >取消</el-button
          >
          <el-button class="qr" type="primary" @click="buySms"
            >去购买</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";
export default {
  data() {
    return {
      dialogVisible: false,
      list: [],
      senddxDialogVisible: false,
      dxbzDialogVisible: false,
      sendList: [],
    };
  },
  mounted() {
    Bus.$on("mssurestuts", (data) => {
      console.log("举报接受到", data);
      this.dialogVisible = data.show;
      this.list = data.list;
    });
  },
  methods: {
    closebox() {
      this.dialogVisible = false;
      // Bus.$emit('reportdstuts', false)
    },
    buySms() {
      this.dxbzDialogVisible = false;

      window.location.href = window.location.origin + "/#/propsmall";
    },
    backed() {
      this.dialogVisible = false;
      this.$emit("changeback", true);
    },
    submit() {
      let configList = [];
      this.list.map((item) => {
        configList.push(item.interview);
      });
      this.$api.saveAllinyerview("post", configList).then((res) => {
        if(res.code === 10200){
          this.sendList = res.data;
          // this.interview = false;
          this.dialogVisible = false;
          this.senddxDialogVisible = true;
        } else {
          this.dialogVisible = false;
          this.$message({
            message: "发送失败" + (res.message ? ": " + res.message : ""),
            type: "error",
          });
        }

        // this.sendList = res.data;
        // this.interview = false;
        // this.$api
        //   .sendInterview("post", {
        //     companyJobId: configList[0].companyJobId,
        //     interviewAddress: configList[0].interviewAddress,
        //     interviewAddressExt: configList[0].interviewAddressExt,
        //     interviewBuild: configList[0].interviewBuild,
        //     interviewContact: configList[0].interviewContact,
        //     interviewDate: configList[0].interviewDate,
        //     interviewPhone: configList[0].interviewPhone,
        //     interviewType: configList[0].interviewType,
        //     lat: configList[0].lat,
        //     lng: configList[0].lng,
        //     userId: configList[0].userId,
        //     userJobId: configList[0].userJobId,
        //   })
        //   .then((resp) => {
        //     this.sendList = resp.data;
        //     // this.interview = false;
        //     this.senddxDialogVisible = true;
        //   });
      });
    },
    closeSmsDialog() {
      this.senddxDialogVisible = false;
      window.location.reload();
    },
    sendMes() {
      this.$api.sendMessage("post", this.sendList).then((res) => {
        if (res.code == 10901) {
          this.senddxDialogVisible = false;
          this.dialogVisible = false;
          this.dxbzDialogVisible = true;
          return;
        }
        if (res.code == 10200) {
          this.senddxDialogVisible = false;
          this.dialogVisible = false;
          this.$message({
            message: "发送成功",
            type: "success",
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          this.senddxDialogVisible = false;
        }
      });
    },
  },
};
</script>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
</style>
<style lang="less" scoped>
.updown_box {
  line-height: 3;
  padding: 60px 0;
  position: relative;
  .from-box-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .closeicon {
    position: absolute;
    top: -60px;
    right: 0;
    font-size: 26px;
    color: #f3f3f3;
  }
  .ub-img {
    text-align: center;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .ub-text {
    text-align: center;
    color: #111111;
  }
  .btnbox {
    text-align: center;
    /deep/ .el-button {
      border-radius: 0px;
      width: 200px;
      margin-top: 50px;
      background: #00bcff;
      border: none;
    }
    .qx {
      background-color: #ffffff;
      color: #00bcff;
      border: 1px solid #00bcff;
      margin-right: 10px;
    }
  }
}
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
.details {
  padding-bottom: 40px;
  .wb-tit {
    padding: 17px 0;
    text-align: center;
    background-color: #f3f3f3;
    color: #000000;
    font-weight: 600;
  }
}

.liuchengbox {
  padding: 20px 40px 20px 0;
  /deep/.el-timeline-item__icon {
    font-size: 20px;
  }
  /deep/.el-card__body {
    padding: 10px 20px;
  }
  /deep/.el-timeline-item__timestamp {
    color: #111111;
    font-size: 20px;
    font-weight: 600;
  }
  /deep/.el-timeline-item__node--large {
    width: 20px;
    height: 20px;
    left: -5px;
    top: 1px;
  }
  /deep/.el-timeline-item__tail {
    border-left: 2px solid #00bcff;
    top: 2px;
  }

  .process {
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 20px 30px;
    .p-left {
      padding-right: 30px;
      .pl-one {
        display: flex;
        align-items: center;
        .p-picurl {
          margin-right: 15px;
          img {
            width: 50px;
            height: 50px;
          }
        }
        .p-info {
          display: flex;
          // align-items: center;
          justify-content: flex-start;
          flex-direction: column;
          flex: 1;
          .pn-name {
            display: flex;
            align-items: center;
            line-height: 1.5;
            .namemain {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              img {
                width: 24px;
                height: 24px;
                margin: 0 18px 0 10px;
              }
            }
          }
          .beizhu {
            color: #666666;
          }
        }
      }
      .pl-two {
        color: #111111;
        margin-top: 20px;
      }
    }
    /deep/.el-divider--vertical {
      height: 4em;
    }
    .p-right {
      display: flex;
      // align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      color: #666666;
      flex: 1;
      padding-left: 30px;
      line-height: 1.8;
    }
  }
}
.btn-caozuo {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  /deep/ .el-button {
    border-radius: 0px;
    background-color: #00bcff;
    border: none;
  }
  button {
    width: 200px;
  }
  .qx {
    border: 1px solid #00bcff;
    background-color: #ffffff;
    color: #00bcff;
  }
}
</style>
