<template>
  <div class="handshake">
    <div class="condition-box">
      <el-row style="padding: 0 30px">
        <el-col :span="8"
          ><el-input
            placeholder="搜索搜索姓名、求职意向"
            @change="selectStatus"
            prefix-icon="el-icon-search"
            v-model="pageInfo.keyword"
            style="width: 260px"
          ></el-input
        ></el-col>
        <el-col :span="16">
          <div class="header-right" style="text-align: right">
            <el-select
              v-model="jobId"
              placeholder="全部职位"
              @change="selectStatus"
              style="width: 130px; margin-right: 50px"
            >
              <el-option
                v-for="item2 in positionoptions"
                :key="item2.value"
                :label="item2.label"
                :value="item2.value"
              >
              </el-option>
            </el-select>
            <el-button v-if="userList != null" type="primary" @click="batch()">
              {{ batchinterview ? "取消批量" : "批量面试" }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- <div class="handshake-tishi">
			您有{{handData.interview}}个面试 ，
			{{handData.offer}}个Offer，
			{{handData.onJob}}位在职，
			{{handData.postCv}}条投递，
			{{handData.talk}}位沟通过
		</div> -->
    <div class="chooseall" v-if="batchinterview">
      <div class="chooseall-left">
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
        >
          全选
        </el-checkbox>
      </div>
      <div class="chooseall-right">
        <el-button type="primary" @click="toAllInterview">发起面试</el-button>
      </div>
    </div>

    <!-- 数据列表 -->
    <div class="listbox" :style="{ 'min-height': sheight + 'px' }">
      <!-- 无数据 -->
      <div class="nodata" v-if="userList == null">
        <img src="../../assets/images/person/p-nodata.png" />
        <div class="font16">当前无数据</div>
      </div>
      <div v-else>
        <el-checkbox-group
            v-model="checkedCities"
            @change="handleCheckedCitiesChange"
        >
          <div class="list" v-for="(itemes, index1) in userList" :key="index1">
            <div class="duoxuan" v-if="batchinterview">
              <div class="duoxuanbox">
                <el-checkbox
                    :label="itemes.id"
                    :disabled="itemes.interviewStatus != -1"
                >{{ "" }}</el-checkbox
                >
              </div>
            </div>
            <div class="list-content" @click="todetail(itemes)">
              <div class="person-info">
                <div class="p-picurl">
                  <img v-if="itemes.avatar && itemes.avatar !== ''"
                      :src="'https://freemen.work/user/file/view/' + itemes.avatar"
                  />
                  <img v-else src="https://freemen.work/user/file/view/files/20220317/ic_launcher_gray_1647507067319.png" />
                  <span
                      class="bofang font20"
                      v-if="itemes.resUrl"
                      @click.stop="videoshow(itemes)"
                  >
                  <!-- <i class="el-icon-video-play"></i> -->
                  <img src="../../assets/images/common/bofangicon.png" />
                </span>
                </div>
                <div class="p-news">
                  <div class="pn-name">
                    <div class="namemain">
                      <span class="font22">{{ itemes.displayName }}</span>
                      <img
                          src="../../assets/images/person/boy.png"
                          v-if="itemes.sex == 1"
                      />
                      <img src="../../assets/images/person/girl.png" v-else />
                      <!-- <span class="font14 Free">Free</span> -->
                      <span class="font14 tui" v-if="itemes.interviewStatus != -1"
                      >已发送过面试邀约</span
                      >
                    </div>
                    <div class="score font14">
                      <span>{{ itemes.score }}</span>
                    </div>
                  </div>
                  <div class="beizhu font14">
                    <div class="" style="margin-right: 15px">
                      {{ itemes.expYear }}年 |
                      <!-- {{itemes.educationList.length ? itemes.educationList[itemes.educationList.length-1].educationType.itemText : '/'}} | {{new Date().getYear() - new Date(itemes.birthday).getYear()}}岁  -->
                      {{
                        new Date().getYear() -
                        new Date(itemes.birthday).getYear() -
                        (new Date().getMonth() >
                        new Date(itemes.birthday).getMonth()
                            ? 0
                            : 1)
                      }}岁 | {{ itemes.jobLevelNames }}
                    </div>
                    <div class="">
                      期望工作时间：{{
                        itemes.startDate ? itemes.startDate.split(" ")[0] : ""
                      }}
                      —
                      {{ itemes.endDate ? itemes.endDate.split(" ")[0] : "/" }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="transitionbox" v-if="itemes.userWorkExpList.length > 0">
                <!-- 3个 -->
                <template v-if="itemes.userWorkExpList.length < 4">
                  <div
                      class="t-one font14"
                      v-for="(item, index) in itemes.userWorkExpList"
                      :key="index"
                  >
                    <img src="../../assets/images/person/gangwei.png" />
                    <span @click.stop="getMoreWork(itemes)">
                    {{ item.companyName }} ·
                    {{ item.jobName }}
                  </span>
                  </div>
                </template>
                <!-- 大于3 -->
                <template v-if="itemes.userWorkExpList.length > 3">
                  <div class="t-one font14">
                    <img src="../../assets/images/person/gangwei.png" />
                    <span @click.stop="getMoreWork(itemes)">
                    {{ itemes.userWorkExpList[0].companyName }} ·
                    {{ itemes.userWorkExpList[0].jobName }}
                  </span>
                    <i
                        :class="
                      itemes.moreShow
                        ? 'el-icon-arrow-up'
                        : 'el-icon-arrow-down'
                    "
                        @click.stop="getMoreWork(itemes)"
                    ></i>
                  </div>
                </template>
                <!-- 大于3 -->
                <el-collapse-transition v-if="itemes.userWorkExpList.length > 3">
                  <div v-show="itemes.moreShow">
                    <div
                        class="t-one font14"
                        v-show="index > 0"
                        v-for="(item, index) in itemes.userWorkExpList"
                        :key="index"
                    >
                      <img src="../../assets/images/person/gangwei.png" />
                      <span>{{ item.companyName }} · {{ item.jobName }}</span>
                    </div>
                  </div>
                </el-collapse-transition>
              </div>
              <div class="label-info">
                <div class="li-left font14">
                <span
                    v-show="index < 5"
                    v-for="(item, index) in itemes.skillIds"
                    :key="index"
                >{{ item.itemText }}</span
                >
                </div>
                <div class="li-right">
                  <el-button
                      class="yy"
                      type="primary"
                      style="background-color: #5e5e5e"
                      @click.stop="noPass(itemes)"
                  >不合适</el-button
                  >
                  <el-button class="gt" type="primary" @click.stop="Chat(itemes)"
                  >沟通</el-button
                  >
                  <el-button
                      class="gt"
                      type="primary"
                      v-if="itemes.interviewStatus == 0"
                      @click.stop="toInterview()"
                  >面试已发起</el-button
                  ><!--  @click.stop="toInterview(itemes)" -->
                  <el-button
                      class="gt"
                      type="primary"
                      v-if="itemes.interviewStatus == 1"
                      @click.stop="toInterview()"
                  >面试已接受</el-button
                  >
                  <el-button
                      class="gt"
                      type="primary"
                      v-if="itemes.interviewStatus == 2"
                      @click.stop="toInterview()"
                  >面试已拒绝</el-button
                  >
                  <el-button
                      class="gt"
                      type="primary"
                      v-if="itemes.interviewStatus == 3"
                      @click.stop="toInterview()"
                  >面试未通过</el-button
                  >
                  <el-button
                      class="gt"
                      type="primary"
                      v-if="itemes.interviewStatus == 4"
                      @click.stop="toInterview()"
                  >面试已通过</el-button
                  >
                  <el-button
                      class="gt"
                      type="primary"
                      v-if="itemes.interviewStatus == 5"
                      @click.stop="toInterview(itemes)"
                  >面试已超时</el-button
                  >
                  <el-button
                      class="yy"
                      type="primary"
                      v-if="itemes.interviewStatus == -1"
                      @click.stop="toInterview(itemes)"
                  >面试邀约</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </el-checkbox-group>
      </div>
      <div class="nextpage" @click="getMoreList" v-if="moreBtn">
        点击加载更多
      </div>
    </div>
    <!-- 分页 -->
    <!-- <div class="paginate" style="position: relative;">
			<el-pagination
				@current-change="handleCurrentChange"
				:current-page="pageInfo.pageNum"
				:page-size="pageInfo.pageSize"
				layout="total, prev, pager, next, jumper"
				:total="pageInfo.count"
			></el-pagination>
		</div> -->
    <!-- :close-on-click-modal="false" -->
    <!-- 批量面试弹窗 -->
    <el-dialog
      :visible.sync="batchDialogVisible"
      width="700px"
      center
      :show-close="false"
      :modal-append-to-body="false"
      class="batch-box-out"
    >
      <div class="batch-box">
        <span class="closeicon" @click="closebatchDialogbox()">
          <i class="el-icon-error"></i>
        </span>
        <div class="wb-tit font18">批量面试邀约</div>
        <div class="frombox">
          <el-form
            :model="batchForm"
            ref="batchForm"
            size="small"
            label-width="130px"
            class="demo-ruleForm"
          >
            <!-- <el-form-item label="面试时间：" prop="startDate" :rules="[{ required: true, message: '请选择日期或时间' }]"> -->
            <el-form-item
              label="面试时间："
              prop="startDate"
              :rules="[{ required: true, message: '请选择面试时间' }]"
            >
              <el-date-picker
                v-model="batchForm.startDate"
                :picker-options="pickerOptions0"
                type="date"
                placeholder="请选择日期"
                style="margin-right: 20px"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
              <!-- <el-time-select
								v-model="interviewTime"
								:picker-options="{
									start: '08:30',
									step: '00:15',
									end: '18:30'
								}"
								placeholder="请选择时间"
								style="width: 200px;">
							</el-time-select> -->
            </el-form-item>
            <el-form-item
              label="面试时间间隔："
              prop="space"
              :rules="[{ required: true, message: '请选择面试时间间隔' }]"
            >
              <el-select
                v-model="batchForm.space"
                placeholder="请选择面试时间间隔"
                style="width: 440px"
              >
                <el-option
                  v-for="item in spaceOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="面试时间上午："
              prop="amEnd"
              :rules="[{ required: true, message: '请选择时间点' }]"
            >
              <el-time-picker
                v-model="batchForm.amStart"
                placeholder="请选择时间"
                format="HH:mm"
                value-format="HH:mm"
                style="width: 210px; margin-right: 20px"
              >
              </el-time-picker>
              <el-time-picker
                v-model="batchForm.amEnd"
                placeholder="请选择时间"
                format="HH:mm"
                value-format="HH:mm"
                style="width: 210px"
              >
              </el-time-picker>
            </el-form-item>
            <el-form-item
              label="面试时间下午："
              prop="pmEnd"
              :rules="[{ required: true, message: '请选择时间点' }]"
            >
              <el-time-picker
                v-model="batchForm.pmStart"
                placeholder="请选择时间"
                format="HH:mm"
                value-format="HH:mm"
                style="width: 210px; margin-right: 20px"
              >
              </el-time-picker>
              <el-time-picker
                v-model="batchForm.pmEnd"
                placeholder="请选择时间"
                format="HH:mm"
                value-format="HH:mm"
                style="width: 210px"
              >
              </el-time-picker>
            </el-form-item>
            <el-form-item
              label="面试方式："
              prop="interviewType"
              :rules="[{ required: true, message: '请选择面试方式' }]"
            >
              <el-select
                v-model="batchForm.interviewType"
                placeholder="请选择面试方式"
                style="width: 440px"
              >
                <el-option
                  v-for="item in fsoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="面试地点："
              prop="interviewAddress"
              :rules="[{ required: true, message: '请选择面试地点' }]"
              v-if="batchForm.interviewType == '1'"
            >
              <el-input
                v-model="adress"
                placeholder="请选择面试地点"
                @click.native="workDialogVisible = true"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="面试联系人："
              prop="interviewContact"
              :rules="[{ required: true, message: '请输入面试联系人' }]"
            >
              <el-input
                v-model="batchForm.interviewContact"
                placeholder="请输入面试联系人"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="联系方式："
              prop="interviewPhone"
              :rules="[{ required: true, message: '请输入联系方式' }]"
            >
              <el-input
                v-model="batchForm.interviewPhone"
                placeholder="请输入联系方式"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="工作地点：" prop="wordaddress" :rules="[{ required: true, message: '请选择工作地点' }]" v-if="batchForm.interviewType=='1'">
							<el-input v-model="batchForm.wordaddress" placeholder="请选择工作地点"></el-input>
						</el-form-item> -->
            <!-- <el-form-item label="备注：" prop="beizhu">
							<el-input
								type="textarea"
								:autosize="{ minRows: 8, maxRows: 10 }"
								show-word-limit
								placeholder="请输入备注事项"
								v-model="batchForm.beizhu"
								style="width: 440px;"
							></el-input>
						</el-form-item> -->
            <el-form-item>
              <!-- @click="submitForm('batchForm')" -->
              <el-button
                type="primary"
                class="btn-next"
                @click="toliucheng('batchForm')"
                >下一步</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
    <!-- 简历弹窗 -->
    <!-- <resumedetails></resumedetails> -->
    <!-- 举报 -->
    <!-- <reportPopup></reportPopup> -->
    <!-- 面试流程 -->
    <Interviewprocess @changeback="changeback"></Interviewprocess>
    <!-- 工作地点 -->
    <!-- 工作地点弹窗 -->
    <!-- :close-on-click-modal="false" -->
    <el-dialog
      :visible.sync="workDialogVisible"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
      class="work-box-out"
    >
      <div class="work-box">
        <span class="closeicon" @click="closeworkDialogbox()">
          <i class="el-icon-error"></i>
        </span>
        <div class="wb-tit font20">工作地点</div>
        <div class="frombox">
          <el-form
            :model="batchForm"
            ref="workForm"
            label-width="150px"
            class="demo-ruleForm"
          >
            <el-form-item
              label="街道/写字楼："
              prop="interviewAddress"
              :rules="[{ required: true, message: '请填写真实地址' }]"
            >
              <el-select
                v-model="tipInput"
                class="tipInput"
                filterable
                remote
                reserve-keyword
                clearable
                placeholder="请输入行政区、街道、写字楼"
                :remote-method="remoteMethod"
                :loading="loading"
                @change="tipChange"
                style="width: 100%"
              >
                <el-option
                  v-for="item in tips"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.name }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.district
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="门牌号："
              prop="interviewBuild"
              :rules="[{ required: true, message: '请填写真实地址' }]"
            >
              <el-input
                type="text"
                style="width: 100%"
                v-model="batchForm.interviewBuild"
                placeholder="请输入行政区、街道、写字楼"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <img
              v-if="!markers.length"
              class="amap-wrapper"
              style="width: 100%"
              src="../../assets/images/common/map.png"
            />
            <div class="amap-wrapper" v-else style="width: 100%">
              <el-amap
                class="amap-box"
                :zoom="zoom"
                :center="center"
                :mapStyle="mapStyle"
              >
                <el-amap-marker
                  v-for="(marker, index) in markers"
                  :position="marker.position"
                  :events="marker.events"
                  :vid="index"
                  :icon="marker.icon"
                  v-bind:key="index"
                >
                </el-amap-marker>
                <el-amap-info-window
                  v-if="window"
                  :position="window.position"
                  :visible="window.visible"
                  :content="window.content"
                  :offset="window.offset"
                  :is-custom="true"
                >
                  <div id="info-window">
                    <p>{{ window.address }}</p>
                  </div>
                </el-amap-info-window>
              </el-amap>
            </div>
            <el-form-item>
              <el-button class="qux" @click="workDialogVisible = false"
                >取消</el-button
              >
              <el-button type="primary" @click="saveAdress('workForm')"
                >保存地址</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
    <el-dialog
        :visible.sync="cardDialogVisible"
        width="600px"
        center
        :show-close="false"
        :modal-append-to-body="false"
    >
      <div class="updown_box">
        <div class="ub-img">
          <img src="../../assets/images/person/yongcard.png" />
        </div>
        <div class="ub-text font22">发起{{ cardDialogFlag === 0 ? "聊天" : "面试" }}将消耗一次聊天卡，确认继续？</div>
        <div class="btnbox">
          <el-button
              class="qx"
              type="primary"
              @click="cardDialogVisible = false"
          >取消</el-button
          >
          <el-button class="qr" type="primary" @click="chatCommit"
          >确认</el-button
          >
        </div>
      </div>
    </el-dialog>
    <!-- 聊天卡额度不足弹窗 -->
    <el-dialog
        :visible.sync="nocardDialogVisible"
        width="600px"
        center
        :show-close="false"
        :modal-append-to-body="false"
    >
      <div class="updown_box">
        <div class="ub-img">
          <img src="../../assets/images/person/qianbao.png" />
        </div>
        <div class="ub-text font22">当前聊天卡额度不足，是否购买？</div>
        <div class="btnbox">
          <el-button
              class="qx"
              type="primary"
              @click="nocardDialogVisible = false"
          >取消</el-button
          >
          <el-button class="qr" type="primary" @click="buyCard"
          >去购买</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import resumedetails from "/src/components/person/resumedetails.vue";//简历详情
// import reportPopup from "/src/components/person/reportPopup.vue";//举报
import location from "../../assets/images/size.png";
import Bus from "../../common/bus.js";
import Interviewprocess from "/src/components/person/Interviewprocess.vue"; //面试流程

export default {
  components: {
    // resumedetails,
    // reportPopup,
    Interviewprocess,
  },
  data() {
    return {
      zoom: 14,
      center: [],
      mapStyle: "amap://styles/8b6be8ec497009e17a708205348b899a", //设置地图样式
      markers: [],
      windows: [],
      tipInput: "",
      loading: false,
      map: undefined,
      tips: [],
      window: "",
      sheight: "",
      handData: {},
      formInline: {
        keyword: "",
        type: "",
        status: "",
        pageNum: 1,
        pageSize: 10,
      },
      pageInfo: {
        keyword: "",
        pageNo: 1,
        pageSize: 10,
        status: 1,
      },
      jobId: "",
      moreBtn: false,
      // 全部状态
      positionoptions: [],
      selectIndex:0,
      selectedLabel:"",
      // 全选

      userList: null,
      checkedCities: [], //选择id
      userListId: [], // 所有id
      checkAll: false,
      isIndeterminate: false, //不全选状态

      // activeNames: ['1'],
      show3: true,
      batchinterview: false, //点击批量面试
      batchDialogVisible: false, //工作地点弹窗
      cardDialogVisible: false, //消耗聊天卡弹窗
      cardDialogFlag: 0,    // 聊天卡弹窗类型 0: 聊天/沟通; 1: 面试
      nocardDialogVisible: false, //聊天卡额度不足弹窗
      batchForm: {
        list: [],
        companyJobId: "",
        startDate: "",
        amStart: "",
        amEnd: "",
        pmEnd: "",
        pmStart: "",
        space: "",
        interviewType: "",
        interviewAddress: "",
        interviewBuild: "",
        interviewContact: "",
        interviewPhone: "",
      },
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; //如果没有后面的-8.64e7就是不可以选择今天的
        },
      },
      interviewDate: "",
      interviewTime: "",
      adress: "",
      // 批量面试假数据
      gzoptions: [
        { value: "09:00", label: "09:00" },
        { value: "10:00", label: "10:00" },
        { value: "11:00", label: "11:00" },
        { value: "12:00", label: "12:00" },
      ],
      fsoptions: [
        { value: 1, label: "线下面试" },
        { value: 2, label: "线上面试" },
      ],
      spaceOptions: [
        { value: 30, label: "0.5小时" },
        { value: 60, label: "1小时" },
        { value: 90, label: "1.5小时" },
        { value: 120, label: "2小时" },
        { value: 150, label: "2.5小时" },
        { value: 180, label: "3小时" },
      ],
      workDialogVisible: false,
      workForm: {
        street: "",
        housenumber: "",
      },
      chatConfig: {
        companyJobId: "",
        userId: "",
        userJobId: "",
      },
    };
  },
  activated() {
    this.getJobList();
    this.sheight = document.documentElement.clientHeight - 380;
  },
  mounted() {},
  methods: {
    tipChange(value) {
      for (const tip of this.tips) {
        if (value === tip.id) {
          this.batchForm.interviewAddress = tip.name;
          let markers = [];
          let windows = [];
          let basePosition = [tip.location.lng, tip.location.lat];
          // let icon = new AMap.Icon({
          // 	image: location,
          // 	size: new AMap.Size(2, 2),
          // 	imageSize: new AMap.Size(2, 2)
          // });
          // content添加相应的内容**********
          markers.push({
            position: basePosition,
            icon: new AMap.Icon({
              image: location,
              size: new AMap.Size(40, 40),
              imageSize: new AMap.Size(40, 40),
            }),
            text: "content1111",
            offset: [0, -50],
          });
          windows.push({
            position: basePosition,
            isCustom: true,
            offset: [10, -40], // 窗体偏移
            visible: true, // 初始是否显示
            address: tip.name,
          });
          this.zoom = 15;
          this.markers = markers;
          this.windows = windows;
          this.center = [tip.location.lng, tip.location.lat];
          this.window = windows[0];
          break;
        }
      }
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        const that = this;
        AMap.plugin("AMap.Autocomplete", function () {
          // 实例化Autocomplete
          const autoOptions = {
            city: "全国",
          };
          const autoComplete = new AMap.Autocomplete(autoOptions);
          autoComplete.search(query, function (status, result) {
            // console.log(result);
            // 搜索成功时，result即是对应的匹配数据
            that.tips = result.tips || [];
            that.loading = false;
          });
        });
      } else {
        this.tips = [];
      }
    },
    getJobList() {
      //获取职位列表
      this.$api.joblist("get").then((res) => {
        // console.log("Handshake, $api.joblist.result = ", res);
        this.positionoptions = [];
        res.data.map((item) => {
          if (item.status == 1) {
            let obj = {
              label: item.jobName,
              value: item.id,
              type:  item.jobType,
            };
            this.positionoptions.push(obj);
          }
          if (this.positionoptions.length > 0) {
            this.jobId = this.positionoptions[0].value;
          }
          // console.log("Handshake, getJobList.result current positionOptions = ", this.positionoptions);
        });
        this.getHandList();
      });
    },
    // getHandData(){ //获取数据
    // 	this.$api.getHandData('get').then(res=>{
    // 		this.handData = res.data
    // 	})
    // },
    getHandList() {
      //获取数据列表
      this.pageInfo.companyJobId = this.jobId;
      // console.log("Handshake, getHangList this.userList = ", this.userList, "--- this.pageInfo.companyJobId = ", this.pageInfo.companyJobId);
      if (this.pageInfo.companyJobId == "") return;
      this.$api.getHangList("get", this.pageInfo).then((res) => {
        if (this.jobId) {
          let arr = [];
          res.data.map((item) => {
            // arr.push(item)

            // if (item.companyJobId == this.jobId) {
            arr.push(item);
            // }
          });
          if (this.pageInfo.pageNo == 1) {
            this.userList = res.data;
          } else {
            this.userList = this.userList.concat(arr);
          }
        } else {
          if (this.pageInfo.pageNo == 1) {
            this.userList = res.data;
          } else {
            this.userList = this.userList.concat(res.data);
          }
        }
        if (res.data.length < 10) {
          this.moreBtn = false;
        } else {
          this.moreBtn = true;
        }
        this.userList.map((item) => {
          item.moreShow = false;
        });
        // console.log("Handshake, getHandList, this.userList = ", this.userList);
      });
    },
    getMoreList() {
      //下一页
      this.pageInfo.pageNo++;
      this.getHandList();
    },
    selectStatus() {
      //选择职位
      // debugger
      this.pageInfo.pageNo = 1;
      this.userList = [];
      this.checkedCities = [];
      this.getHandList();
      for(var i=0; i< this.positionoptions.length; i++){
        if(this.jobId === this.positionoptions[i].value){
          this.selectIndex = i;
          this.selectedLabel = this.positionoptions[i].label;
        }
      }
    },
    getMoreWork(data) {
      //展示所有工作经历
      data.moreShow = !data.moreShow;
      this.$forceUpdate();
    },
    toInterview(data) {
      //面试邀约
      // console.log("handshake toInterview ----------- this.positionOptions", this.positionoptions, ", date =", data);
      if(data){
        this.$interview.show({
          show: true,
          jobList: this.positionoptions,
          userInfo: data,
          selected: data.companyJobId ? data.companyJobId : -1,
          cardCheck: 1,
        });
      }
    },
    toAllInterview() {
      //发起批量面试

      if (this.checkedCities.length) {
        this.batchDialogVisible = true;
        this.$nextTick(() => {
          this.$refs["batchForm"].resetFields();
        });
        this.batchForm = {
          list: [],
          companyJobId: "",
          startDate: "",
          amStart: "",
          amEnd: "",
          pmEnd: "",
          pmStart: "",
          space: "",
          interviewType: "",
          interviewAddress: "",
          interviewBuild: "",
          interviewContact: "",
          interviewPhone: "",
        };
      } else {
        this.$message({
          message: "请选择面试人员",
          type: "error",
        });
      }
    },
    changeback(e) {
      this.batchDialogVisible = e;
    },
    toliucheng(formName) {
      //批量面试预览
      // let y = this.interviewDate.getFullYear();
      // let m = this.interviewDate.getMonth() + 1;
      // let d = this.interviewDate.getDate();
      // let h = this.batchForm.pmStart.getHours();
      // let f = this.batchForm.pmStart.getMinutes();
      // let s = this.batchForm.pmStart.getSeconds();
      // let h1 = this.batchForm.pmEnd.getHours();
      // let f1 = this.batchForm.pmEnd.getMinutes();
      // let s1 = this.batchForm.pmEnd.getSeconds();
      // let amh = this.batchForm.amStart.getHours();
      // let amf = this.batchForm.amStart.getMinutes();
      // let ams = this.batchForm.amStart.getSeconds();
      // let amh1 = this.batchForm.amEnd.getHours();
      // let amf1 = this.batchForm.amEnd.getMinutes();
      // let ams1 = this.batchForm.amEnd.getSeconds();
      // this.batchForm.startDate = y+'-'+m+'-'+d+' '+this.interviewTime+':00'
      // this.batchForm.pmStart = `${this.batchForm.pmStart}:00`;
      // this.batchForm.pmEnd = `${this.batchForm.pmEnd}:00`;
      // this.batchForm.amStart = `${this.batchForm.amStart}:00`;
      // this.batchForm.amEnd = `${this.batchForm.amEnd}:00`;
      // this.batchForm.pmEnd = h1 + ":" + f1 + ":" + s1;
      // this.batchForm.amStart = amh + ":" + amf + ":" + ams;
      // this.batchForm.amEnd = amh1 + ":" + amf1 + ":" + ams1;
      // this.batchForm.startDate = y + "-" + m + "-" + d;

      // let a = 1;
      // if (a == 1) {
      //   console.log(this.batchForm);
      //   return;
      // }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let arr = [];
          this.batchForm.companyJobId = this.jobId;
          this.userList.map((item) => {
            if (this.checkedCities.indexOf(item.id) != -1) {
              let obj = {
                userId: item.userId,
                userJobId: item.userJobId,
              };
              arr.push(obj);
            }
          });
          this.batchForm.list = arr;
          if (this.center.length > 0) {
            this.batchForm["lng"] = this.center[0];
            this.batchForm["lat"] = this.center[1];
          }

          this.batchForm.amEnd = this.batchForm.amEnd + ":00";
          this.batchForm.amStart = this.batchForm.amStart + ":00";
          this.batchForm.pmEnd = this.batchForm.pmEnd + ":00";
          this.batchForm.pmStart = this.batchForm.pmStart + ":00";
          this.$api.allInterviewSee("post", this.batchForm).then((res) => {
            this.batchDialogVisible = false;
            Bus.$emit("mssurestuts", {
              show: true,
              list: res.data,
            });
          });
        } else {
          return false;
        }
      });
    },
    closeworkDialogbox() {
      this.workDialogVisible = false;
    },
    sendoffer() {
      Bus.$emit("workingplacestuts", true);
    },
    todetail(data) {
      //简历详情
      this.$resumeDetail.show({
        show: true,
        companyJobId: data.companyJobId,
        userJobId: data.userJobId,
        positionoptions: this.positionoptions,
        callback: () => {
          let tempQuery = {
            active: 5,
            companyJobId: data.companyJobId,
            companyJobName: data.companyJobName,
            userJobId: data.userJobId,
            userId: data.userId,
          }
          this.$router.push({path: "chatmain", query: tempQuery});
        },
      });
    },
    videoshow(data) {
      //视频详情
      let config = {
        show: true,
        data: data,
      };
      this.$videoDetail.show(config);
      // Bus.$emit('handvideodstuts', config)
    },
    noPass(data) {
      //不合适
      this.$api.noPass("post", { id: data.id }).then(() => {
        this.pageInfo.pageNo = 1;
        this.userList = [];
        this.checkedCities = [];
        this.getHandList();
        this.$message({
          message: "操作成功",
          type: "success",
        });
      });
    },
    // handleChangecollapse(val) {
    //   console.log("handleChangeCollapse",val);
    // },
    getdata() {
      this.userList = [
        {
          id: 1,
          position: "安卓开发工程师",
          name: "张珊",
          Flag: false,
          show3: false,
        },
        {
          id: 2,
          position: "前端开发工程师",
          name: "李四",
          Flag: false,
          show3: false,
        },
        {
          id: 3,
          position: "后端开发工程师",
          name: "王五",
          Flag: false,
          show3: false,
        },
        {
          id: 4,
          position: "架构开发工程师",
          name: "孙六",
          Flag: false,
          show3: false,
        },
      ];
      this.userListId = [1, 2, 3, 4]; //初始化多选选择框
    },
    fetchData(page = 1) {
      // this.table_loading = true;
      this.pageInfo.pageNum = page;
      this.formInline.pageNum = this.pageInfo.pageNum;
      this.getdata();
    },
    handleCurrentChange(val) {
      this.fetchData(val);
    },
    closebatchDialogbox() {
      this.batchDialogVisible = false;
    },
    edititem() {
      this.$router.push("/editposition");
    },

    batch() {
      this.batchinterview = !this.batchinterview;
    },
    // 单个多选框
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      // this.checkAll = checkedCount === this.userList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.userList.length;
    },
    // 全选
    handleCheckAllChange(val) {
      this.isIndeterminate = false;
      if (val) {
        let arr = [];
        this.userList.map((item) => {
          if (item.interviewStatus == -1) {
            arr.push(item.id);
          }
        });
        this.checkedCities = arr;
      } else {
        this.checkedCities = [];
      }
    },
    saveAdress(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.workDialogVisible = false;
          this.adress =
            this.batchForm.interviewAddress + this.batchForm.interviewBuild;
        } else {
          return false;
        }
      });
    },
    Chat(data) {
      // console.log("handshake Chat current user info data = ", data);
      if (this.positionoptions[this.selectIndex].type === 1) { // 驻场职业跳过来聊天卡判断 freeChat
        this.chatConfig.companyJobId = data.companyJobId;
        this.chatConfig.userId = data.userId;
        this.chatConfig.userJobId = data.userJobId;
        this.$api.freeChat("post", this.chatConfig).then(() => {
          let tempQuery = {
            active: 1,
            companyJobId: data.companyJobId,
            companyJobName: data.companyJobName,
            userJobId: data.userJobId,
            userId: data.userId,
          }
          this.$router.push({ path: "chatmain", query: tempQuery});
        });
      } else {  // 入住职位 聊天卡判断， 需要使用聊天卡
        this.$api.checkChatCard("get",
            {
              companyJobId: data.companyJobId,
              userJobId: data.userJobId,
            })
            .then((res) => {
              // console.log("handshake Chat, $api.checkChatCard。result = ", res);
              if (res.data > 0) {
                let tempQuery = {
                  active: 1,
                  companyJobId: data.companyJobId,
                  companyJobName: data.companyJobName,
                  userJobId: data.userJobId,
                  userId: data.userId,
                }
                this.$router.push({ path: "chatmain", query: tempQuery});
              } else {
                this.chatConfig.companyJobId = data.companyJobId;
                this.chatConfig.userId = data.userId;
                this.chatConfig.userJobId = data.userJobId;
                this.cardDialogFlag = 0;
                this.cardDialogVisible = true;
              }
            });
      }
    },
    chatCommit() {
      this.$api.useChatCard("post", this.chatConfig).then((res) => {
        this.cardDialogVisible = false;
        if (res.code == "10901") {
          this.nocardDialogVisible = true;
        } else {
          if(this.cardDialogFlag === 0){
            this.selectedLabel = this.positionoptions[this.selectIndex].label;
            let tempQuery = {
              active: 1,
              companyJobId: this.chatConfig.companyJobId,
              companyJobName: this.selectedLabel,
              userJobId: this.chatConfig.userJobId,
              userId: this.chatConfig.userId,
            }
            this.$router.push({path:"chatmain", query: tempQuery})
          } else if(this.cardDialogFlag === 1) {
            // todo
          }
        }
      });
    },
    buyCard() {
      this.nocardDialogVisible = false;
      this.dialogVisible = false;
      window.location.href = window.location.origin + "/#/propsmall";
    },
  },
};
</script>

<style type="text/css">
.tipInput {
  width: 300px;
}
.amap-wrapper {
  width: 500px;
  height: 250px;
  margin-bottom: 20px;
}
#info-window {
  padding: 0px 20px;
  background: #ffffff;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}
.el-form-item__label {
  color: #111111;
  /* font-weight: 600; */
}
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
</style>

<style lang="less" scoped>
.updown_box {
  line-height: 3;
  padding: 40px 0;
  .ub-img {
    text-align: center;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .ub-text {
    text-align: center;
    color: #111111;
  }
  .btnbox {
    text-align: center;
    /deep/ .el-button {
      border-radius: 0px;
      width: 200px;
      margin-top: 50px;
      background: #00bcff;
      border: none;
    }
    .qx {
      background-color: #ffffff;
      color: #00bcff;
      border: 1px solid #00bcff;
      margin-right: 10px;
    }
  }
}
.work-box-out {
  .work-box {
    position: relative;
    .wb-tit {
      padding: 17px 0;
      text-align: center;
      background-color: #f3f3f3;
      color: #000000;
      font-weight: 600;
    }
    /deep/ .el-input__inner {
      border-radius: 0px;
    }
    /deep/ .el-button {
      border-radius: 0px;
    }
    .frombox {
      padding: 50px 60px 30px 60px;
      .demo-ruleForm {
        button {
          width: 160px;
          height: 40px;
          background-color: #00bcff;
        }
        .qux {
          background-color: #fff;
          border: 1px solid #00bcff;
          color: #00bcff;
        }
      }
    }
  }
}
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
/deep/ .el-input__inner {
  border-radius: 0px;
}
.handshake {
  .handshake-tishi {
    margin-top: 20px;
    background-color: #feecde;
    text-align: center;
    color: #ff5400;
    padding: 14px 0;
    box-shadow: 0px 1px 29px 17px rgb(0 0 0 / 3%);
  }
  .chooseall {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    padding: 10px 30px;
    margin-top: 30px;

    .chooseall-right {
      /deep/ .el-button {
        border-radius: 0px;
        background-color: #ffa544;
        border: none;
      }
    }
  }
  .condition-box {
    background-color: #ffffff;
    padding: 22px 0;
    box-shadow: 0px 1px 29px 17px rgb(0 0 0 / 3%);
    /deep/ .el-input__inner {
      border-radius: 0px;
      border: none;
      background-color: #f3f3f3;
    }

    .header-right {
      /deep/ .el-input__inner {
        border-radius: 0px;
      }
      /deep/ .el-button {
        border-radius: 0px;
        background-color: #00bcff;
        border: none;
      }
    }
  }
}

.listbox {
  .nodata {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #111111;
    padding: 37px 0 20px 0;
    img {
      width: 256px;
      height: 215px;
      margin-bottom: 20px;
    }
  }
  .nextpage {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999999;
    cursor: pointer;
    margin-top: 15px;
  }
  .list:hover {
    transform: scale(1.02);
    background-color: rgba(0, 0, 0, 0.001);
  }
  .list {
    // padding: 0 30px;
    background-color: #ffffff;
    margin-top: 20px;
    box-shadow: 0px 1px 29px 17px rgb(0 0 0 / 3%);
    padding: 20px 30px;
    display: flex;
    .duoxuan {
      width: 30px;
      .duoxuanbox {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .list-content {
      display: flex;
      flex: 1;
      flex-direction: column;
      .person-info {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #dddddd;
        padding-bottom: 20px;
        .p-picurl {
          margin-right: 20px;
          position: relative;
          img {
            width: 50px;
            height: 50px;
          }
          .bofang:hover {
            cursor: pointer;
          }
          .bofang {
            position: absolute;
            top: -3px;
            right: -6px;
            // border-radius: 50px;
            // color: #3F3F3F;
            img {
              width: 22px;
              height: 22px;
            }
          }
        }
        .p-news {
          display: flex;
          flex: 1;
          flex-direction: column;
          line-height: 1.6;
          .pn-name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .namemain {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              img {
                width: 24px;
                height: 24px;
                margin: 0 18px 0 10px;
              }

              .Free {
                background-color: #e9f9ff;
                color: #00bcff;
                padding: 2px 10px;
                margin-right: 6px;
              }
              .tui {
                background-color: #f3f3f3;
                color: #999999;
                padding: 2px 10px;
                margin-right: 6px;
              }
            }
            .score {
              background-color: #00bcff;
              border-radius: 8px 8px 8px 0;
              color: #ffffff;
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .beizhu {
            color: #666666;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            span {
              margin-right: 48px;
            }
          }
        }
      }
      .transitionbox {
        .t-one:hover {
          cursor: pointer;
        }
        .t-one {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: #666666;
          margin-top: 20px;
          img {
            width: 22px;
            height: 20px;
          }
          span {
            margin: 0 30px 0 10px;
          }
        }
      }
      .label-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        .li-left {
          span {
            background-color: #eeeeee;
            padding: 4px 8px;
            margin-right: 10px;
            color: #666666;
          }
        }
        .li-right {
          display: flex;
          align-items: center;
          /deep/ .el-button {
            border-radius: 0px;
            height: 40px;
            margin-left: 30px;
            border: none;
          }
          .gt {
            background-color: #00bcff;
          }
          .yy {
            background-color: #ffa544;
          }
        }
      }
    }

    //
    .p-shu {
      color: #666666;
      line-height: 2;
    }
    .p-info {
      color: #666666;
      line-height: 2;
      .p-tit {
        color: #111;
        font-weight: 600;
      }
    }
    .p-remarks {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 58px;
      div {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        color: #666666;
      }
    }
    .p-operation {
      display: flex;
      div {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        img {
          width: 58px;
          height: 58px;
        }
      }
    }
  }
}

.batch-box-out {
  .batch-box {
    position: relative;
    .wb-tit {
      padding: 17px 0;
      text-align: center;
      background-color: #f3f3f3;
      color: #000000;
      font-weight: 600;
    }
    /deep/ .el-input__inner,
    /deep/ .el-textarea__inner {
      border-radius: 0px;
    }
    /deep/ .el-button {
      border-radius: 0px;
    }
    .frombox {
      padding: 50px 80px 50px 50px;
      .demo-ruleForm {
        .btn-next {
          width: 100%;
          height: 40px;
          background-color: #00bcff;
        }
      }
    }
  }
}
</style>
